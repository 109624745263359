$(function() {

(function($) { "use strict";
console.log('Ghada Rahhal Resume');	
var $circle = $(".follow");
var $circle2 = $(".follow2");
var $circle3 = $(".follow3");

function moveCircle(e) {
  /*	TweenLite.to($circle, 1.2, {
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
  */
  TweenLite.to($circle, 1.5, {
    ease: Elastic.easeOut.config(1, 0.3),
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
  TweenLite.to($circle2, 1.8, {
    ease: Elastic.easeOut.config(1, 0.3),
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
  TweenLite.to($circle3, 2.2, {
    ease: Elastic.easeOut.config(1, 0.3),
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
}

$('.contact-section').on("mousemove", moveCircle);


	
	//About page
	
	$(".about-text").on('click', function () {
		$("body").addClass("about-on");
	});
	$(".about-close").on('click', function () {
		$("body").removeClass("about-on");
	});

	
	//Contact page
	
	$(".contact-text").on('click', function () {
		$("body").addClass("contact-on");
	});
	$(".contact-close").on('click', function () {
		$("body").removeClass("contact-on");
	});

	
	//Travel portfolio page
	
	$(".resume").on('click', function () {
		$("body").addClass("resume-on");
	});
	$(".resume-close").on('click', function () {
		$("body").removeClass("resume-on");
	});

	
	//Wildlife portfolio page
	
	$(".portfolio").on('click', function () {
		$("body").addClass("portfolio-on");
	});
	$(".portfolio-close").on('click', function () {
		$("body").removeClass("portfolio-on");
	});

	
	//Nature portfolio page
	
	$(".skills").on('click', function () {
		$("body").addClass("skills-on");
	});
	$(".skills-close").on('click', function () {
		$("body").removeClass("skills-on");
	});

	
})(jQuery); 

});